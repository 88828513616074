<template>
  <div>
    <b-input
      v-model="queryString"
      v-bind="$attrs"
      class="fxd-algolia-input-control"
      @keydown.up.stop.prevent.native="moveUp"
      @keydown.down.stop.prevent.native="moveDown"
      @keyup.enter.native="onEnter"
      @keyup.esc.native="queryString = ''"
    />
    <algolia-search
      v-if="queryString"
      ref="algoliaSearch"
      :query="algoliaQuery"
      :debounce="debounce"
      @results="results = $event"
      @fallback-to-firebase="$emit('fallback-to-firebase')"
    >
      <template slot-scope="props">
        <slot
          name="list"
          v-bind="{ ...props, hits: indexHits, focused, indexFocused }"
        ></slot>
      </template>
    </algolia-search>
  </div>
</template>

<script>
export default {
  name: "AlgoliaSelect",
  components: {
    "algolia-search": () => import("@shared/algolia/_algoliaSearch.vue")
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    config: {
      type: Array,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    debounce: {
      type: Number,
      default: 150
    }
  },
  data() {
    return {
      results: {},
      queryString: "",
      indexFocused: -1
    };
  },
  computed: {
    algoliaQuery() {
      return this.$_.map(this.config, i => ({
        ...i,
        indexName: this.index,
        query: this.queryString
      }));
    },
    indexResult() {
      return this.$_.find(this.results, i => i.index === this.index);
    },
    indexHits() {
      return this.$_.get(this.indexResult, "hits");
    },
    focused() {
      return this.$_.get(this.indexHits, `[${this.indexFocused}]`, null);
    }
  },
  watch: {
    value() {
      this.queryString = this.value;
    },
    queryString(value) {
      this.indexFocused = -1;
      this.$emit("input", value);
    }
  },
  async created() {
    await this.$store.dispatch("algolia/observe", "agentCredentials");
  },
  beforeDestroy() {
    this.$store.dispatch("algolia/unobserve", "agentCredentials");
  },
  methods: {
    onEnter() {
      this.$emit("focusedselected", this.focused);
    },
    moveDown() {
      if (this.$_.get(this.indexHits, "length") - 1 > this.indexFocused) {
        this.indexFocused++;
        this.$emit("movedown");
      }
    },
    moveUp() {
      if (0 <= this.indexFocused) {
        this.indexFocused--;
        this.$emit("moveup");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fxd-algolia-input-control {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
